import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { DbUtils, MoveTransactionType, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import {
    CheckInput,
    ElectricityFormFields,
    ElectricityMeterType,
    electricityMeterTypeOptions,
    exclusiveNightOption,
    hasInjectionOption,
    UiDirection,
} from '@smooved/ui';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, map, take, takeUntil } from 'rxjs/operators';
import {
    AdminElectricityForm,
    AdminElectricityFormFields,
    automaticDigitalReadingOptionRental,
    automaticDigitalReadingOptionSale,
    fieldContexts,
} from './meter-info-electricity.constants';
import { ElectricityMeter } from '@smooved-lib/lib/meters/types/electricity-meter';
import { validatedOptionElectricity } from '@app/energy/components/meter-info/meter-info.constants';
import { ElectricityMeterStore } from '@smooved-lib/lib/meters/store/electricity-meter.store';

@Component({
    selector: 'app-meter-info-electricity',
    templateUrl: './meter-info-electricity.component.html',
    styleUrls: ['./meter-info-electricity.component.scss'],
})
export class MeterInfoElectricityComponent extends RxjsComponent implements OnInit, OnChanges {
    @Input() public isEots: boolean;
    @Input() public move: Move;
    @Input() public showTitle = true;
    @Input() public layout = UiDirection.Horizontal;
    @Input() public disabled = false;
    @Input() public readingDisabled = false;
    // Optional: only to be used when moving date is handled outside this component.
    @Input() public movingDate: Date;
    @Input() public show: boolean;

    @Output() public meterTypeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public formChange: EventEmitter<void> = new EventEmitter<void>();

    public showElectricity: boolean;
    public form: UntypedFormGroup;
    public controls = { ...ElectricityFormFields, ...AdminElectricityFormFields };

    public uiDirection = UiDirection;
    public meterType = ElectricityMeterType;

    public meterTypeOptions = electricityMeterTypeOptions;
    public exclusiveNightOption = exclusiveNightOption;
    public automaticDigitalReadingOption: CheckInput<boolean>;
    public fieldContexts = fieldContexts;
    public isAdmin = false;

    private moveTransactionType: MoveTransactionType;

    private electricityMeterStore = inject(ElectricityMeterStore);
    private moveSandbox = inject(MoveSandbox);
    private authenticationSandbox = inject(AuthenticationSandbox);
    private route = inject(ActivatedRoute);
    private cdr = inject(ChangeDetectorRef);
    private formBuilder = inject(UntypedFormBuilder);

    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.authenticationSandbox.isAdminOnce$.subscribe(async (isAdmin) => {
            this.isAdmin = isAdmin;

            this.form = this.formBuilder.group({
                [ElectricityFormFields.MeterType]: [{ value: ElectricityMeterType.Single, disabled: this.disabled }],
                [ElectricityFormFields.ExclusiveNight]: [{ value: false, disabled: this.disabled }],
                [ElectricityFormFields.EanCode]: [{ value: null, disabled: this.disabled }],
                [ElectricityFormFields.ValidatedElectricity]: [{ value: false, disabled: this.disabled }],
                [ElectricityFormFields.AutomaticDigitalReadings]: [
                    {
                        value: false,
                        disabled: this.disabled,
                    },
                ],
                [ElectricityFormFields.MovingDate]: [{ value: null, disabled: true }],
                [ElectricityFormFields.SingleMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.DoubleDayMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.DoubleNightMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
                [ElectricityFormFields.HasInjection]: [{ value: false, disabled: this.disabled }],
                [ElectricityFormFields.ConsumptionDayMeterReading]: [
                    {
                        value: null,
                        disabled: !this.canEditMeterReading(),
                    },
                ],
                [ElectricityFormFields.ConsumptionNightMeterReading]: [
                    {
                        value: null,
                        disabled: !this.canEditMeterReading(),
                    },
                ],
                [ElectricityFormFields.InjectionDayMeterReading]: [
                    {
                        value: null,
                        disabled: !this.canEditMeterReading(),
                    },
                ],
                [ElectricityFormFields.InjectionNightMeterReading]: [
                    {
                        value: null,
                        disabled: !this.canEditMeterReading(),
                    },
                ],
                [ElectricityFormFields.ExclusiveNightMeterReading]: [
                    {
                        value: null,
                        disabled: !this.canEditMeterReading(),
                    },
                ],
            });

            this.form.addControl(
                AdminElectricityFormFields.MeterNumberByAdmin,
                this.formBuilder.control({
                    value: null,
                    disabled: this.disabled,
                })
            );

            if (this.move) {
                const { transferee } = MoveUtils.getMovers(this.move);
                await this.electricityMeterStore.load(DbUtils.getStringId(transferee));

                const energyType = this.move?.energyOffer?.energyType;
                const showElectricity =
                    this.show || !energyType || EnergyUtils.hasElectricity(energyType) || !!MoveUtils.energyTransferSelected(this.move);

                this.moveTransactionType = MoveUtils.getMoveTransactionType(this.move);
                this.init(this.move, this.electricityMeterStore.meter(), showElectricity);
            } else {
                combineLatest([
                    this.moveSandbox.moveOnce$,
                    this.moveSandbox.energyTransferSelectedOnce$,
                    this.moveSandbox.energyTypeHasElectricity$.pipe(take(1)),
                    this.route.queryParams.pipe(take(1)),
                ])
                    .pipe(
                        concatMap(([moveState, energyTransferSelected, energyTypeHasElectricity, { id }]): Observable<any> => {
                            const moveId = id || DbUtils.getStringId(moveState);
                            if (!moveId) {
                                return of([moveState || null, energyTypeHasElectricity || !!energyTransferSelected]);
                            } else {
                                return this.moveSandbox.get(moveId).pipe(
                                    map((move) => {
                                        const energyType = move.energyOffer?.energyType;
                                        const showElectricity =
                                            !energyType || EnergyUtils.hasElectricity(energyType) || !!energyTransferSelected;
                                        return [move, showElectricity];
                                    }),
                                    catchError((e) => of([moveState || null, energyTypeHasElectricity || !!energyTransferSelected]))
                                );
                            }
                        })
                    )
                    .subscribe(async ([move, showElectricity]) => {
                        const { transferee } = MoveUtils.getMovers(move);
                        await this.electricityMeterStore.load(DbUtils.getStringId(transferee));

                        this.init(move, this.electricityMeterStore.meter(), showElectricity);
                    });
            }
        });
    }

    public ngOnChanges({ movingDate, disabled, readingDisabled }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(disabled) || SimpleChangesUtils.hasChanged(readingDisabled)) {
            this.setFormControlDisabled(ElectricityFormFields.MeterType, this.disabled);
            this.setFormControlDisabled(ElectricityFormFields.ExclusiveNight, this.disabled);
            this.setFormControlDisabled(ElectricityFormFields.EanCode, this.disabled);
            this.setFormControlDisabled(ElectricityFormFields.ValidatedElectricity, this.disabled);
            this.setFormControlDisabled(ElectricityFormFields.AutomaticDigitalReadings, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.SingleMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.DoubleDayMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.DoubleNightMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.ConsumptionDayMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.ConsumptionNightMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.InjectionDayMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.InjectionNightMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(ElectricityFormFields.ExclusiveNightMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(AdminElectricityFormFields.MeterNumberByAdmin, this.disabled);
            this.setFormControlDisabled(ElectricityFormFields.HasInjection, this.disabled);
        }

        if (SimpleChangesUtils.hasChanged(movingDate)) this.form?.get(ElectricityFormFields.MovingDate).setValue(this.movingDate);
    }

    /**
     * Function used in viewchild
     * @returns Move patch
     */
    public createPatch(): ElectricityMeter {
        let patch: ElectricityMeter = {
            readings: {},
        };

        if (this.showElectricity) {
            patch.meterType = this.meterTypeFormControl().value;
        }

        this.buildPatchForExclusiveNight(patch, this.showElectricity);

        patch.eanCode =
            !!this.eanCodeElectricityFormControl().value && this.showElectricity ? this.eanCodeElectricityFormControl().value : null;
        patch.validated = !!this.form.get(ElectricityFormFields.ValidatedElectricity).value;
        patch.meterNumber = this.showElectricity && this.form.get(AdminElectricityFormFields.MeterNumberByAdmin).value;
        patch.hasInjection = this.showElectricity && this.form.get(ElectricityFormFields.HasInjection).value;

        switch (this.meterTypeFormControl().value) {
            case ElectricityMeterType.Single:
                this.buildPatchSingle(patch, this.showElectricity);
                break;
            case ElectricityMeterType.Digital:
                patch = { ...patch, ...this.buildPatchDigital(patch.hasInjection) };
                break;
            case ElectricityMeterType.Double:
            case ElectricityMeterType.Unknown:
                this.buildPatchDouble(patch, this.showElectricity);
                break;
            default:
        }

        return patch;
    }

    private init(move: Move, electricityMeter: ElectricityMeter, showElectricity: boolean): void {
        this.showElectricity = showElectricity;

        if (!move || !showElectricity) {
            return;
        }

        this.automaticDigitalReadingOption =
            this.moveTransactionType === MoveTransactionType.Sale ? automaticDigitalReadingOptionSale : automaticDigitalReadingOptionRental;

        this.initMeterReadings(move, electricityMeter);
        if (this.disabled) return;
        this.initListeners();
        this.cdr.detectChanges();
        this.handleMeterTypeChange(electricityMeter.meterType);
    }

    private initMeterReadings(move: Move, electricityMeter: ElectricityMeter): void {
        this.meterTypeFormControl().patchValue(electricityMeter.meterType);
        this.form.get(ElectricityFormFields.MovingDate).patchValue(this.movingDate || move.movingDate);
        if (electricityMeter.meterType === ElectricityMeterType.Single) {
            this.electricitySingleMeterReadingFormControl().patchValue(electricityMeter?.readings?.meterReading);
        } else if (electricityMeter.meterType === ElectricityMeterType.Double) {
            this.electricityDoubleDayMeterReadingFormControl().patchValue(electricityMeter?.readings?.meterReading);
            this.electricityDoubleNightMeterReadingFormControl().patchValue(electricityMeter?.readings?.meterReadingNight);
        } else if (electricityMeter.meterType === ElectricityMeterType.Digital) {
            this.form.get(ElectricityFormFields.AutomaticDigitalReadings).patchValue(electricityMeter.automaticReading);
            this.form.get(ElectricityFormFields.ConsumptionDayMeterReading).patchValue(electricityMeter?.readings?.meterReading);
            this.form.get(ElectricityFormFields.ConsumptionNightMeterReading).patchValue(electricityMeter?.readings?.meterReadingNight);
            this.form.get(ElectricityFormFields.HasInjection).patchValue(electricityMeter?.hasInjection);
            if (electricityMeter?.hasInjection) {
                this.form
                    .get(ElectricityFormFields.InjectionDayMeterReading)
                    .patchValue(electricityMeter?.readings?.meterReadingInjectionDay);
                this.form
                    .get(ElectricityFormFields.InjectionNightMeterReading)
                    .patchValue(electricityMeter?.readings?.meterReadingInjectionNight);
            }
        }

        this.exclusiveNightFormControl().patchValue(electricityMeter?.exclusiveNight);
        this.electricityExclusiveNightMeterReadingFormControl().patchValue(electricityMeter?.readings?.meterReadingExclusiveNight);

        this.eanCodeElectricityFormControl().patchValue(electricityMeter?.eanCode);
        this.form.get(ElectricityFormFields.ValidatedElectricity).patchValue(electricityMeter?.validated);
        this.form.get(AdminElectricityFormFields.MeterNumberByAdmin).patchValue(electricityMeter?.meterNumber);
    }

    private initListeners(): void {
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((x) => {
            this.formChange.emit();
        });
        this.form
            .get(ElectricityFormFields.MeterType)
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((meterType) => this.handleMeterTypeChange(meterType));
    }

    private buildPatchForExclusiveNight(patch: any, showElectricity: boolean): void {
        patch.exclusiveNight = this.form.get(ElectricityFormFields.ExclusiveNight).value;
        patch.readings.exlusiveNightMeterReading =
            !!showElectricity && !!patch.electricityExclusiveNight
                ? this.form.get(ElectricityFormFields.ExclusiveNightMeterReading).value
                : null;
    }

    private buildPatchSingle(patch, showElectricity): void {
        patch.readings.meterReading =
            showElectricity && !!this.electricitySingleMeterReadingFormControl().value
                ? this.electricitySingleMeterReadingFormControl().value
                : null;
        patch.readings.meterReadingNight = null;
        patch.readings.meterReadingInjectionDay = null;
        patch.readings.meterReadingInjectionNight = null;
    }

    private buildPatchDouble(patch, showElectricity): void {
        patch.readings.meterReading =
            showElectricity && !!this.electricityDoubleDayMeterReadingFormControl().value
                ? this.electricityDoubleDayMeterReadingFormControl().value
                : null;
        patch.readings.meterReadingNight = this.electricityDoubleNightMeterReadingFormControl().value
            ? this.electricityDoubleNightMeterReadingFormControl().value
            : null;
        patch.readings.meterReadingInjectionDay = null;
        patch.readings.meterReadingInjectionNight = null;
    }

    private buildPatchDigital(hasInjection: boolean): ElectricityMeter {
        const {
            automaticDigitalReadings,
            consumptionDayMeterReading,
            consumptionNightMeterReading,
            injectionDayMeterReading,
            injectionNightMeterReading,
        } = <AdminElectricityForm>this.form.getRawValue();

        return {
            automaticReading: automaticDigitalReadings,
            readings: {
                meterReading: consumptionDayMeterReading,
                meterReadingNight: consumptionNightMeterReading,
                meterReadingInjectionDay: hasInjection ? injectionDayMeterReading : null,
                meterReadingInjectionNight: hasInjection ? injectionNightMeterReading : null,
            },
        };
    }

    private electricitySingleMeterReadingFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.SingleMeterReading);
    }

    private electricityDoubleDayMeterReadingFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.DoubleDayMeterReading);
    }

    private electricityDoubleNightMeterReadingFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.DoubleNightMeterReading);
    }

    private electricityExclusiveNightMeterReadingFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.ExclusiveNightMeterReading);
    }

    private eanCodeElectricityFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.EanCode);
    }

    public exclusiveNightFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.ExclusiveNight);
    }

    public hasInjectionFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.HasInjection);
    }

    public meterTypeFormControl(): AbstractControl {
        return this.form.get(ElectricityFormFields.MeterType);
    }

    private handleMeterTypeChange(meterType: ElectricityMeterType): void {
        this.meterTypeChange.emit(meterType === ElectricityMeterType.Digital);
    }

    private setFormControlDisabled(formControlName: ElectricityFormFields | AdminElectricityFormFields, disabled: boolean): void {
        this.form?.get(formControlName)[disabled ? 'disable' : 'enable']();
    }

    private canEditMeterReading(): boolean {
        return !(this.disabled || this.readingDisabled);
    }

    protected readonly validatedOptionElectricity = validatedOptionElectricity;
    protected readonly hasInjectionOption = hasInjectionOption;
}
