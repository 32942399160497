import { Component, OnInit } from '@angular/core';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MoveData } from '@app/move/classes/move-data.class';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';

@Component({
    selector: 'app-meter-info-base',
    template: ``,
})
export abstract class MeterInfoBaseComponent extends MoveData implements OnInit {
    protected data: Partial<ModalDataMove>;

    constructor(
        protected moveSandbox: MoveSandbox,
        protected authenticationSandbox: AuthenticationSandbox
    ) {
        super(moveSandbox, null);
    }

    public ngOnInit(): void {
        this.updateMove(this.data.move);
        this.patchedSubject = this.data.patchedSubject;
    }

    protected getInitialEnergyType(): EnergyType {
        return this.data.move.energyOffer?.energyType || EnergyType.Both;
    }

    protected getPatchData(): Partial<Move> {
        return {};
    }

    protected abstract hasAssets(): boolean;

    protected abstract isDirty(): boolean;
}
