<form [formGroup]="form" *ngIf="showElectricity">
    <div class="u-flex-column u-flex-align-items-start u-w100p">
        <h6 *ngIf="showTitle" class="u-color-muted u-margin-bottom">{{ 'ELECTRICITY' | translate }}</h6>

        <app-label-container [label]="'METER_TYPE' | translate" [hasMarginDouble]="true" class="u-w100p" [hasMargin]="false">
            <div class="u-flex-column u-flex-align-items-start">
                <app-select-input
                    [custom]="false"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    width="auto"
                    class="u-margin-right-double-tablet-portrait-up u-margin-bottom-up-to-and-including-phone-landscape"
                    [formControlName]="controls.MeterType"
                    ngDefaultControl
                    [direction]="uiDirection.Vertical"
                    [options]="meterTypeOptions"
                ></app-select-input>

                <app-check-input
                    [custom]="false"
                    width="auto"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    [option]="exclusiveNightOption"
                    [formControlName]="controls.ExclusiveNight"
                    ngDefaultControl
                    class="u-margin-top"
                >
                </app-check-input>
            </div>
        </app-label-container>

        <app-ean-code-input
            class="u-margin-bottom-half"
            [formControlName]="controls.EanCode"
            [hasMargin]="false"
            ngDefaultControl
        ></app-ean-code-input>

        <smvd-ui-text-input
            [label]="'ENERGY.METER_NUMBER.LABEL' | translate"
            [formControlName]="controls.MeterNumberByAdmin"
            ngDefaultControl
        ></smvd-ui-text-input>

        <app-check-input
            *ngIf="isAdmin"
            [option]="validatedOptionElectricity"
            class="u-margin-bottom"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [custom]="false"
            width="auto"
            [formControlName]="controls.ValidatedElectricity"
        >
        </app-check-input>
        <div class="u-margin-bottom-triple u-mw100p">
            <div
                [ngClass]="{
                    'u-flex-responsive u-flex-align-items-center': layout === uiDirection.Horizontal,
                    'u-flex-column': layout === uiDirection.Vertical,
                }"
                *ngIf="!isEots"
                [ngSwitch]="meterTypeFormControl().value"
            >
                <ng-container *ngSwitchCase="meterType.Single">
                    <ng-container
                        [ngTemplateOutlet]="fieldContent"
                        [ngTemplateOutletContext]="{ field: fieldContexts.singleMeterReading }"
                    ></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="meterType.Double">
                    <ng-container
                        [ngTemplateOutlet]="fieldContent"
                        [ngTemplateOutletContext]="{ field: fieldContexts.doubleDayMeterReading }"
                    ></ng-container>
                    <ng-container
                        [ngTemplateOutlet]="fieldContent"
                        [ngTemplateOutletContext]="{ field: fieldContexts.doubleNightMeterReading }"
                    ></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="meterType.Digital">
                    <div class="u-flex-column u-margin-top-double">
                        <div
                            class="u-margin-bottom u-mw100p"
                            [ngClass]="{
                                'u-flex-responsive u-flex-align-items-center': layout === uiDirection.Horizontal,
                                'u-flex-column': layout === uiDirection.Vertical,
                            }"
                        >
                            <ng-container
                                [ngTemplateOutlet]="fieldContent"
                                [ngTemplateOutletContext]="{ field: fieldContexts.consumptionDayMeterReading }"
                            >
                            </ng-container>
                            <ng-container
                                [ngTemplateOutlet]="fieldContent"
                                [ngTemplateOutletContext]="{ field: fieldContexts.consumptionNightMeterReading }"
                            >
                            </ng-container>
                        </div>
                        <app-check-input
                            [custom]="false"
                            width="auto"
                            [hasMarginDouble]="false"
                            [option]="hasInjectionOption"
                            [formControlName]="controls.HasInjection"
                            ngDefaultControl
                        >
                        </app-check-input>
                        @if (hasInjectionFormControl()?.value) {
                            <div
                                class="u-margin-bottom u-mw100p"
                                [ngClass]="{
                                    'u-flex-responsive u-flex-align-items-center': layout === uiDirection.Horizontal,
                                    'u-flex-column': layout === uiDirection.Vertical,
                                }"
                            >
                                <ng-container
                                    [ngTemplateOutlet]="fieldContent"
                                    [ngTemplateOutletContext]="{ field: fieldContexts.injectionDayMeterReading }"
                                >
                                </ng-container>
                                <ng-container
                                    [ngTemplateOutlet]="fieldContent"
                                    [ngTemplateOutletContext]="{ field: fieldContexts.injectionNightMeterReading }"
                                >
                                </ng-container>
                            </div>
                        }
                    </div>

                    <app-check-input
                        [custom]="false"
                        width="300px"
                        [hasMargin]="false"
                        [hasMarginDouble]="false"
                        [option]="automaticDigitalReadingOption"
                        formControlName="automaticDigitalReadings"
                        ngDefaultControl
                        class="u-margin-bottom-half"
                    >
                    </app-check-input>
                </ng-container>

                <ng-template #fieldContent let-field="field">
                    <app-meter-reading-input
                        [formControlName]="field.control"
                        ngDefaultControl
                        [label]="field.label | translate"
                        [hasMargin]="false"
                        [ngClass]="{
                            'u-margin-right-tablet-portrait-up u-margin-bottom-up-to-and-including-phone-landscape':
                                layout === uiDirection.Horizontal && !field.noMargins,
                            'u-margin-bottom': layout === uiDirection.Vertical && !field.noMargins,
                        }"
                        [hasMarginDouble]="false"
                    ></app-meter-reading-input>
                </ng-template>
            </div>
            <div *ngIf="!!exclusiveNightFormControl().value && !isEots" class="u-margin-top">
                <app-meter-reading-input
                    [formControlName]="controls.ExclusiveNightMeterReading"
                    ngDefaultControl
                    [label]="'METER_READING_EXCLUSIVE_NIGHT' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                >
                </app-meter-reading-input>
            </div>
        </div>
    </div>
</form>
