<form
    class="u-flex-column u-flex-align-items-start u-w100p"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    *ngIf="{
        isRealEstateAgentOrAdmin: (authenticationSandbox.isRealEstateAgentOrAdmin$ | async),
    } as vm"
>
    <smvd-app-meter-info-state [move]="data.move" class="__meters-info u-display-block u-margin-bottom-double"></smvd-app-meter-info-state>
    <div class="u-flex-responsive u-margin-bottom-triple" [ngSwitch]="moveTransactionType">
        <app-alert *ngSwitchCase="moveTransactionTypes.Sale" [context]="uiContext.Danger" icon="warning" class="__alert">
            <h6 class="u-margin-bottom">{{ 'ENERGY.CONFIRM_DEED_DATE_ALERT.TITLE' | translate }}</h6>
            <p>{{ 'ENERGY.CONFIRM_DEED_DATE_ALERT.INFO' | translate }}</p>
        </app-alert>
        <app-alert *ngSwitchDefault [context]="uiContext.Danger" icon="warning" class="__alert">
            <h6 class="u-margin-bottom">{{ 'ENERGY.CONFIRM_TRANSFER_DATE_ALERT.TITLE' | translate }}</h6>
            <p>{{ 'ENERGY.CONFIRM_TRANSFER_DATE_ALERT.INFO' | translate }}</p>
        </app-alert>
        <div>
            <h6 class="u-color-muted u-margin-bottom u-margin-top">{{ 'MOVE.MOVING_DATE' | translate }}</h6>
            <app-moving-date-input
                [formControlName]="meterInfoForm.MovingDate"
                [label]="
                    (moveTransactionType === moveTransactionTypes.Sale ? 'MOVE.MOVING_DATE_SALE' : 'MOVE.MOVING_DATE_RENTAL') | translate
                "
                class="u-display-block"
            ></app-moving-date-input>
        </div>
    </div>
    <div class="u-flex-responsive u-margin-bottom-triple">
        <div class="u-flex-column">
            <app-check-input
                [option]="optionElectricity"
                class="u-margin-bottom"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                [custom]="false"
                width="auto"
                [formControlName]="meterInfoForm.HasElectricity"
                #checkInputElectricity
            >
            </app-check-input>
            <app-meter-info-electricity
                *ngIf="data.move"
                class="__electricity"
                layout="vertical"
                [disabled]="isDisabled || !checkInputElectricity.checked"
                [move]="data.move"
                [show]="true"
                [showTitle]="false"
                (meterTypeChange)="meterInfoGasComponent?.meterTypeIsDigitalListener($event)"
            >
            </app-meter-info-electricity>
        </div>
        <div class="u-flex-column">
            <app-check-input
                [option]="optionGas"
                class="u-margin-bottom"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                [custom]="false"
                width="auto"
                [formControlName]="meterInfoForm.HasGas"
                #checkInputGas
            >
            </app-check-input>
            <app-meter-info-gas
                *ngIf="data.move"
                [move]="data.move"
                [disabled]="isDisabled || !checkInputGas.checked"
                [show]="true"
                [showTitle]="false"
            ></app-meter-info-gas>

            <app-energy-meter-reading-assets-input
                [move]="data.move"
                [readOnly]="isDisabled"
                class="u-margin-top-double"
                (detail)="onEnergyMeterReadingDetail($event)"
            >
            </app-energy-meter-reading-assets-input>
        </div>
    </div>

    <div class="u-text-align-right u-w100p">
        <button smvd-ui-button type="submit" [disabled]="isDisabled || !vm.isRealEstateAgentOrAdmin">{{ 'SAVE' | translate }}</button>
    </div>
</form>
