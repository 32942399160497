<app-info-modal *ngIf="electricityMeterStore.meter() && gasMeterStore.meter() && transfereeMove$ | async as move">
    <ng-container modal-header>
        <div class="u-flex-column">
            <span id="modal-header-title" class="u-font-size-medium u-color-gray-ultradark u-font-weight-semi-bold"
                >{{ 'METER_READINGS' | translate }}</span
            >
            <span id="modal-header-address" class="u-font-size-default u-color-gray-dark">{{ move.user?.formattedMovingAddress }}</span>
        </div>
    </ng-container>

    <form class="__energy-meters-info-modal-container u-flex-column u-flex-align-items-start" [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-alert
            *ngIf="move?.track.energyMeterReadings.energyMetersState === EnergyMetersState.Processed"
            [context]="uiContext.Success"
            icon="check_circle"
            [iconContext]="uiContext.Success"
            class="u-w100p u-margin-bottom-xs u-margin-top-xs"
        >
            <h6 class="u-margin-bottom-xs">{{ 'ENERGY.ENERGY_METER_INFO.PROCESSED.INFO.TITLE' | translate }}</h6>
            <p
                [innerHTML]="'ENERGY.ENERGY_METER_INFO.PROCESSED.INFO.BODY' | translate: {href: moveSandbox.meterInfoTransfereeContactUsLink$ | async}"
            ></p>
        </app-alert>
        <div class="u-divider u-padding-bottom-none">
            <h3 class="u-margin-bottom-xs u-font-size-24 u-color-gray-ultradark">{{ 'ENERGY.METER_READING.DATE' | translate }}</h3>
            <div class="u-color-gray-dark u-margin-bottom">{{ 'ENERGY.METER_READING.TRANSFER_DATE_INFO' | translate }}</div>
            <app-label-container [required]="true" [showRequiredAsterisk]="true" [label]="'ENERGY.METER_READING.DATE' | translate">
                <app-moving-date-input
                    id="moving-date-input"
                    [formControlName]="meterInfoForm.MovingDate"
                    class="u-display-block"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                ></app-moving-date-input>
            </app-label-container>
        </div>
        <div class="u-w100p">
            <h3 class="u-margin-bottom-xs u-font-size-24 u-color-gray-ultradark">
                {{ 'ENERGY.METER_READING.METER_INFO.LABEL' | translate }}
            </h3>
            <div class="u-color-gray-dark u-margin-bottom-md">{{ 'ENERGY.METER_READING.METER_INFO.DESCRIPTION' | translate }}</div>
            <div class="u-flex-responsive u-flex-gap">
                <div class="u-flex-one-half u-background-gray-ultralight u-border-radius-large u-padding">
                    <smvd-ui-meter-readings-electricity
                        [formControlName]="meterInfoForm.ElectricityMeters"
                        [transactionType]="transactionType$ | async"
                        [showAutoReadout]="true"
                        (meterTypeChanged)="meterReadingsGas?.meterTypeChanged($event)"
                    ></smvd-ui-meter-readings-electricity>
                </div>
                <div class="u-flex-one-half u-background-gray-ultralight u-border-radius-large u-padding">
                    <smvd-ui-meter-readings-gas
                        [formControlName]="meterInfoForm.GasMeters"
                        [initialMeterType]="electricityMeterStore.meter()?.meterType"
                    ></smvd-ui-meter-readings-gas>
                </div>
            </div>
        </div>

        <div class="__meter-info-divider-container u-flex u-flex-row u-flex-align-items-center">
            <div class="__meter-info-divider-container-line"></div>
            <span class="u-font-weight-semi-bold u-font-size-12 u-color-gray-dark u-padding-x-axis-half"
                >{{ 'COMMON.OR' | uppercase | translate }}</span
            >
            <div class="__meter-info-divider-container-line"></div>
        </div>

        <div class="u-w100p u-padding u-border-radius u-margin-bottom-double u-background-gray-ultralight">
            <div class="u-flex u-flex-align-items-center">
                <app-svg-illustration [svg]="svgIllustration.File" class="u-margin-right-xs"></app-svg-illustration>
                <h3 class="u-margin-bottom-xs u-font-size-24 u-color-gray-ultradark">{{ 'ATTACHMENTS' | translate }}</h3>
            </div>
            <div class="u-color-gray-dark u-margin-bottom">{{ 'ENERGY.METER_READING.ATTACHMENTS.INFO' | translate }}</div>

            <div
                *ngFor="let item of uploader.queue"
                class="__energy-meters-info-modal-container-attachments u-flex u-flex-align-items-center u-flex-justify-content-space-between u-margin-bottom-half"
            >
                <div class="u-link-inverted u-flex u-flex-align-items-center" (click)="openFileDetail(item)">
                    <app-svg-illustration
                        class="__energy-meters-info-modal-container-attachments-file-icon u-margin-right-xs"
                        [svg]="svgIllustration.File"
                    ></app-svg-illustration>
                    <span class="__energy-meters-info-modal-container-attachments-file-name u-text-truncate"> {{ item?.file?.name }} </span>
                </div>
                <a
                    app-svg-illustration
                    class="u-color-gray-dark u-cursor-pointer"
                    [svg]="svgIllustration.CloseButton"
                    (click)="removeAsset(item)"
                    *ngIf="move?.track.energyMeterReadings.energyMetersState !== EnergyMetersState.Processed"
                ></a>
            </div>
            <button
                [appearance]="buttonAppearance.Stroked"
                [context]="uiContext.Muted"
                [size]="buttonSize.Default"
                class="u-font-weight-semi-bold u-margin-top-half"
                id="add-attachments-disabled-button"
                disabled
                smvd-ui-button
                *ngIf="move?.track.energyMeterReadings.energyMetersState === EnergyMetersState.Processed; else canSaveAttachments"
            >
                {{ 'ENERGY.METER_READING.ATTACHMENTS.CTA' | translate }}
            </button>
            <ng-template #canSaveAttachments>
                <button
                    [appearance]="buttonAppearance.Stroked"
                    [context]="uiContext.PrimaryDark"
                    [size]="buttonSize.Default"
                    class="u-font-weight-semi-bold u-margin-top-half"
                    (click)="fileInput.click()"
                    id="add-attachments-enabled-button"
                    smvd-ui-button
                >
                    {{ 'ENERGY.METER_READING.ATTACHMENTS.CTA' | translate }}
                </button>
            </ng-template>
            <input
                #fileInput
                hidden
                [accept]="allowedMimeTypes"
                type="file"
                ng2FileSelect
                (onFileSelected)="onFileSelected($event)"
                [uploader]="uploader"
                multiple
            />
            <div class="u-color-gray-dark u-margin-bottom u-font-size-12 u-margin-top-xs">
                {{ 'ENERGY.METER_READING.ATTACHMENTS.FILES_SUPPORT' | translate }}
            </div>
        </div>

        <div class="u-color-gray-dark u-margin-top-xs">{{ 'ENERGY.METER_READING.DISCLAIMER' | translate }}</div>

        <div
            class="u-text-align-right u-w100p u-margin-top-triple"
            *ngIf="move?.track.energyMeterReadings.energyMetersState !== EnergyMetersState.Processed"
        >
            <button smvd-ui-button [disabled]="form.pristine" [context]="uiContext.PrimaryDark" class="__button-save" type="submit">
                {{ 'SAVE' | translate }}
            </button>
        </div>
    </form>
</app-info-modal>
