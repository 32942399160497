import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import {
    ArrayUtils,
    Asset,
    DbUtils,
    EnergyMetersState,
    FileUtils,
    HttpUtils,
    Mimetypes,
    MoveTransactionType,
    ObjectUtils,
    RxjsComponent,
    skipWhileNull,
} from '@smooved/core';
import {
    ButtonAppearance,
    ButtonSize,
    ElectricityForm,
    ElectricityFormFields,
    gasControlNames,
    GasMetersForm,
    MeterReadingsElectricityComponent,
    MeterReadingsGasComponent,
    ModalSandbox,
    NotificationSandbox,
    SvgIllustration,
    UiContext,
} from '@smooved/ui';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { BehaviorSubject, concat, last, map, Observable, take, takeUntil } from 'rxjs';
import { FilePreviewModal } from '../file-preview/file-preview.modal';
import { EnergyMetersInfoForm, meterInfoFields } from './energy-meters-info.constants';
import { ElectricityMeterStore } from '@smooved-lib/lib/meters/store/electricity-meter.store';
import { GasMeterStore } from '@smooved-lib/lib/meters/store/gas-meter.store';
import { ElectricityMeter, ElectricityMeterReadingsResponse } from '@smooved-lib/lib/meters/types/electricity-meter';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { GasMeter } from '@smooved-lib/lib/meters/types/gas-meter';
import { ElectricityMeterType } from '@smooved-lib/lib/meters/enums/electricity-meter-type';

@Component({
    selector: 'smvd-app-energy-meters-info-modal',
    templateUrl: 'energy-meters-info.modal.html',
    styleUrls: ['energy-meters-info.modal.scss'],
})
export class EnergyMetersInfoModal extends RxjsComponent implements OnInit, AfterViewInit {
    @ViewChild(MeterReadingsElectricityComponent, { static: false }) meterReadingsElectricity: MeterReadingsElectricityComponent;
    @ViewChild(MeterReadingsGasComponent, { static: false }) meterReadingsGas: MeterReadingsGasComponent;

    @Output() public success = new EventEmitter<Move>();

    public readonly meterInfoForm = meterInfoFields;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly uiContext = UiContext;
    public readonly svgIllustration = SvgIllustration;
    public readonly EnergyMetersState = EnergyMetersState;

    public readonly moveSandbox = inject(MoveSandbox);
    public readonly electricityMeterStore = inject(ElectricityMeterStore);
    public readonly gasMeterStore = inject(GasMeterStore);
    private readonly formBuilder = inject(FormBuilder);
    private readonly notificationSandbox = inject(NotificationSandbox);
    private readonly modalSandbox = inject(ModalSandbox);
    private readonly http = inject(HttpClient);
    private readonly dialogRef = inject(MatDialogRef<EnergyMetersInfoModal>);

    public readonly form: FormGroup = this.formBuilder.group({
        [meterInfoFields.MovingDate]: [null, Validators.required],
        [meterInfoFields.ElectricityMeters]: null,
        [meterInfoFields.GasMeters]: null,
    });
    public transfereeMove$: Observable<Move>;
    public transactionType$: Observable<MoveTransactionType>;
    public removedFiles: File[] = [];

    public uploader: FileUploader;
    public readonly allowedMimeTypes = [Mimetypes.Jpeg, Mimetypes.Png, Mimetypes.Pdf];

    private readonly allowedFileTypes = ['image', 'pdf'];
    private readonly maxFileSize = 10; // Max size in MB
    private readonly hasAssetsSubject = new BehaviorSubject<boolean>(false);

    constructor(private readonly uiSandbox: AppUiSandbox) {
        super();
    }

    public ngOnInit(): void {
        this.uploader = new FileUploader({
            url: '',
            disableMultipart: true,
            formatDataFunctionIsAsync: true,
            allowedFileType: this.allowedFileTypes,
            allowedMimeType: this.allowedMimeTypes,
            maxFileSize: FileUtils.convertMegaBytesToBytes(this.maxFileSize),
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => {
                    resolve({
                        name: item._file.name,
                        length: item._file.size,
                        contentType: item._file.type,
                        date: new Date(),
                    });
                });
            },
        });

        this.transfereeMove$ = this.moveSandbox.move$.pipe(map(this.getTransfereeMove));
        this.transactionType$ = this.transfereeMove$.pipe(map(this.mapTransactionType));

        this.transfereeMove$.pipe(take(1), skipWhileNull()).subscribe(async (move) => {
            await this.electricityMeterStore.load(DbUtils.getStringId(move));
            await this.gasMeterStore.load(DbUtils.getStringId(move));
            this.formFactory(move);
            this.handleAssetsInit(move);
        });

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onEditForm);
    }

    public ngAfterViewInit(): void {
        this.transfereeMove$.pipe(take(1), skipWhileNull()).subscribe((move) => {
            if (move?.track.energyMeterReadings.energyMetersState === EnergyMetersState.Processed) return;
            this.meterReadingsElectricity?.electricityForm
                .get(ElectricityFormFields.Active)
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe(this.setGasToggle);
            this.meterReadingsGas?.gasForm
                .get(gasControlNames.active)
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe(this.setElectricityToggle);

            this.setElectricityToggle(this.meterReadingsGas?.gasForm.get(gasControlNames.active).value);
            this.setGasToggle(this.meterReadingsElectricity?.electricityForm.get(ElectricityFormFields.Active).value);
        });
    }

    public onSubmit(): void {
        if (this.form.invalid) return;
        this.patchMove();
    }

    public openFileDetail(file: FileItem): void {
        /**
         *  file.rawFile can be two types:
         *  1 - blob, when it's coming from uploader (selected in input[type=file]);
         *  2 - FileInput, when file is already in Database. In this case, fileInput is set on rawFile of FileItem;
         *
         * */
        const fileType: 'FileInput' | 'Blob' = !!(file.file.rawFile as unknown as FileInput)?.location ? 'FileInput' : 'Blob';

        if (fileType === 'FileInput') {
            const fileInput = file.file.rawFile as unknown as FileInput;
            if (fileInput.mime === Mimetypes.Pdf) {
                this.http
                    .get(fileInput.location, { responseType: 'blob' })
                    .pipe(take(1))
                    .subscribe((blob) => FileUtils.downloadAsset(blob, fileInput.name));
                return;
            }
        }

        if (fileType === 'Blob') {
            const fileInput = file.file.rawFile as File;
            if (fileInput.type === Mimetypes.Pdf) {
                FileUtils.downloadAsset(fileInput, file.file.name);
                return;
            }
        }

        /** If not a PDF, open modal to preview image */
        this.modalSandbox.openModal(FilePreviewModal, { data: file }, null, FilePreviewModal, { data: file }, null);
    }

    public onFileSelected(list: FileList): void {
        for (const item of Array.from(list)) {
            if (item.size > FileUtils.convertMegaBytesToBytes(this.maxFileSize)) {
                this.notificationSandbox.error('ENERGY.INPUT.FILE_ERROR.SIZE', { maxFileSize: this.maxFileSize });
                break;
            }

            if (!this.allowedMimeTypes.includes(item.type as Mimetypes)) {
                this.notificationSandbox.error('ENERGY.INPUT.FILE_ERROR.TYPE', {
                    allowedTypes: this.allowedMimeTypes.map((mime) => ArrayUtils.last(mime.split('/'))).join(', '),
                });
                break;
            }
        }
        this.onEditForm();
    }

    public removeAsset(item: FileItem): void {
        this.removedFiles.push(item.file.rawFile as File);
        this.uploader.removeFromQueue(item);
        this.onEditForm();
    }

    public patchMove(): void {
        this.uiSandbox.showLoadingOverlay();
        this.transfereeMove$.pipe(take(1)).subscribe(async (move) => {
            const patchData: {
                move: Partial<Move>;
                electricityMeter: ElectricityMeter;
                gasMeter: GasMeter;
            } = this.mapEnergyMetersInfo(this.form.getRawValue());
            await this.electricityMeterStore.patch(DbUtils.getStringId(move), patchData.electricityMeter);
            await this.gasMeterStore.patch(DbUtils.getStringId(move), patchData.gasMeter);
            this.moveSandbox.patchProperty(
                '',
                patchData.move,
                true,
                (updatedMove) => this.uploadEnergyAssets(updatedMove || move),
                false,
                move,
                true,
                false,
                false,
                true
            );
        });
    }

    protected onMetersUpdateSuccess(updatedMove: Move): void {
        this.moveSandbox.energyMeterReadingsManual(DbUtils.getStringId(updatedMove));
        if (updatedMove) this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
        this.transfereeMove$.subscribe((move) => this.success.emit(updatedMove || move));
        this.moveSandbox.setMoveState(updatedMove);
        this.dialogRef.close(updatedMove);
    }

    protected onUploadSuccess = (patchedMoveAfterAssets: Move): void => {
        this.moveSandbox.energyMeterReadingsAttachmentUploaded(DbUtils.getStringId(patchedMoveAfterAssets));
        this.uiSandbox.hideLoadingOverlay();
        this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
        this.success.emit(patchedMoveAfterAssets);
        this.moveSandbox.setMoveState(patchedMoveAfterAssets);
        this.dialogRef.close(patchedMoveAfterAssets);
    };

    private setHasAssets(): void {
        this.hasAssetsSubject.next(!ArrayUtils.isEmpty(this.uploader.queue));
    }

    private onEditForm = (): void => {
        this.form.markAsDirty();
        this.setHasAssets();
    };

    private formFactory = (move: Move): void => {
        this.form.patchValue({
            [meterInfoFields.MovingDate]: move.movingDate,
            [meterInfoFields.ElectricityMeters]: this.mapElectricityMeterReadingsForm(move, this.electricityMeterStore.meter()),
            [meterInfoFields.GasMeters]: this.mapGasMeterReadingsForm(move, this.gasMeterStore.meter()),
        });

        if (move?.track?.energyMeterReadings?.energyMetersState === EnergyMetersState.Processed) {
            this.form.get(meterInfoFields.MovingDate).disable();
            this.form.get(meterInfoFields.ElectricityMeters).disable();
            this.form.get(meterInfoFields.GasMeters).disable();
        }
    };

    private handleAssetsInit(move: Move): void {
        const existingFiles = this.uploader.queue.map((item) => item._file as File);
        const uploadedAssets = [...(move.energyDocumentAssets ?? []), ...(move.energyMeterReadingAssets ?? [])] as unknown as File[];

        const filesToAdd = uploadedAssets.filter(
            (file) => !existingFiles.some((existingFile) => existingFile.name === file.name && existingFile.size === file.size)
        );

        if (!ArrayUtils.isEmpty(filesToAdd) && this.uploader.queue.length === 0) {
            this.uploader.addToQueue(filesToAdd);
        }

        this.setHasAssets();
    }

    private getTransfereeMove = (move: Move): Move => {
        const { transferee } = MoveUtils.getMovers(move);
        return transferee;
    };

    private mapElectricityMeterReadingsForm(move: Move, electricityMeter: ElectricityMeter): ElectricityForm {
        const { energyOffer } = move;
        return {
            active: EnergyUtils.hasElectricity(move.energyOffer?.energyType) || !!MoveUtils.energyTransferSelected(move),
            meterType: electricityMeter.meterType,
            eanCodeElectricity: electricityMeter.eanCode,
            meterNumberElectricity: electricityMeter.meterNumber,
            electricitySingleMeterReading:
                electricityMeter.meterType === ElectricityMeterType.Single ? electricityMeter?.readings?.meterReading : null,
            electricityDoubleDayMeterReading: [ElectricityMeterType.Double, ElectricityMeterType.Unknown].includes(
                electricityMeter.meterType
            )
                ? electricityMeter.readings?.meterReading
                : null,
            electricityDoubleNightMeterReading: [ElectricityMeterType.Double, ElectricityMeterType.Unknown].includes(
                electricityMeter.meterType
            )
                ? electricityMeter.readings?.meterReadingNight
                : null,
            exclusiveNight: electricityMeter.exclusiveNight,
            electricityExclusiveNightMeterReading: electricityMeter.readings?.meterReadingExclusiveNight,
            automaticDigitalReadings: electricityMeter.automaticReading,
            consumptionDayMeterReading:
                electricityMeter.meterType === ElectricityMeterType.Digital ? electricityMeter?.readings?.meterReading : null,
            consumptionNightMeterReading:
                electricityMeter.meterType === ElectricityMeterType.Digital ? electricityMeter?.readings?.meterReadingNight : null,
            hasInjection: electricityMeter.hasInjection ?? false,
            injectionDayMeterReading:
                electricityMeter.meterType === ElectricityMeterType.Digital ? electricityMeter?.readings?.meterReadingInjectionDay : null,
            injectionNightMeterReading:
                electricityMeter.meterType === ElectricityMeterType.Digital ? electricityMeter?.readings?.meterReadingInjectionNight : null,
        };
    }

    private mapTransactionType = (move: Move): MoveTransactionType => MoveUtils.getMoveTransactionType(move);

    private mapGasMeterReadingsForm(move: Move, gasMeter: GasMeter): GasMetersForm {
        return {
            active: MoveUtils.showMeterReadingGas(move) || !!MoveUtils.energyTransferSelected(move),
            eanCodeGas: gasMeter.eanCode,
            meterNumberGas: gasMeter.meterNumber,
            gasMeterNumber: gasMeter.eanCode,
            gasMeterReading: gasMeter.meterReading,
            automaticDigitalReadings: gasMeter.automaticReading,
        };
    }

    private mapEnergyType = (electricityActive: boolean, gasActive: boolean): EnergyType =>
        !electricityActive ? EnergyType.Gas : !gasActive ? EnergyType.Electricity : EnergyType.Both;

    private mapEnergyMetersInfo = ({
        movingDate,
        electricityMeters,
        gasMeters,
    }: EnergyMetersInfoForm): {
        move: Partial<Move>;
        electricityMeter: ElectricityMeter;
        gasMeter: GasMeter;
    } => {
        const { active: electricityActive, meterType, hasInjection, eanCodeElectricity, exclusiveNight } = electricityMeters || {};
        const { active: gasActive, eanCodeGas } = gasMeters || {};
        const move: Partial<Move> = {
            movingDate,
            energyOffer: {
                meterType,
                energyType: this.mapEnergyType(electricityActive, gasActive),
            },
        };
        const electricityMeter: ElectricityMeter = {
            meterType,
            eanCode: eanCodeElectricity,
            meterNumber: electricityMeters?.meterNumberElectricity,
            exclusiveNight: exclusiveNight,
            hasInjection,
            automaticReading: meterType === ElectricityMeterType.Digital ? electricityMeters?.automaticDigitalReadings : false,
            readings: this.getElectricityMeterReadingsPatch(electricityMeters),
        };
        const gasMeter: GasMeter = {
            eanCode: eanCodeGas,
            meterNumber: gasMeters?.meterNumberGas,
            meterReading: gasMeters?.gasMeterReading,
            automaticReading: meterType === ElectricityMeterType.Digital ? gasMeters?.automaticDigitalReadings : false,
        };
        const result = {
            move,
            electricityMeter,
            gasMeter,
        };
        ObjectUtils.removeEmpty(result, true);
        return result;
    };

    private getElectricityMeterReadingsPatch(electricityForm: ElectricityForm): ElectricityMeterReadingsResponse {
        switch (electricityForm.meterType) {
            case ElectricityMeterType.Single:
                return this.buildPatchSingle(electricityForm);
            case ElectricityMeterType.Digital:
                return this.buildPatchDigital(electricityForm);
            case ElectricityMeterType.Double:
            case ElectricityMeterType.Unknown:
                return this.buildPatchDouble(electricityForm);
            default:
                return {};
        }
    }

    private buildPatchSingle(electricityForm: ElectricityForm): ElectricityMeterReadingsResponse {
        return {
            meterReading: electricityForm.electricitySingleMeterReading,
            meterReadingNight: null,
            meterReadingInjectionDay: null,
            meterReadingInjectionNight: null,
            meterReadingExclusiveNight: electricityForm.exclusiveNight ? electricityForm.electricityExclusiveNightMeterReading : null,
        };
    }

    private buildPatchDouble(electricityForm: ElectricityForm): ElectricityMeterReadingsResponse {
        return {
            meterReading: electricityForm.electricityDoubleDayMeterReading,
            meterReadingNight: electricityForm.electricityDoubleDayMeterReading,
            meterReadingInjectionDay: null,
            meterReadingInjectionNight: null,
            meterReadingExclusiveNight: electricityForm.exclusiveNight ? electricityForm.electricityExclusiveNightMeterReading : null,
        };
    }

    private buildPatchDigital(electricityForm: ElectricityForm): ElectricityMeterReadingsResponse {
        return {
            meterReading: electricityForm.consumptionDayMeterReading,
            meterReadingNight: electricityForm.consumptionNightMeterReading,
            meterReadingInjectionDay: electricityForm.injectionDayMeterReading,
            meterReadingInjectionNight: electricityForm.injectionNightMeterReading,
            meterReadingExclusiveNight: electricityForm.exclusiveNight ? electricityForm.electricityExclusiveNightMeterReading : null,
        };
    }

    private uploadEnergyAssets(move: Move): void {
        const filesToAdd = this.getFilesToAdd(move);
        const filesToRemove = this.getFilesToRemove(move);

        const httpCalls = [...this.getUploadCalls(filesToAdd, move), ...this.getDeleteCalls(filesToRemove, move)];

        if (!ArrayUtils.isEmpty(httpCalls)) {
            this.uiSandbox.showLoadingOverlay();

            concat(...httpCalls)
                .pipe(last())
                .subscribe({ next: this.onUploadSuccess, error: this.onUploadFail });
        } else {
            this.onMetersUpdateSuccess(move);
        }
    }

    private getFilesToAdd(move: Move): File[] {
        const uploadedAssets = [...(move?.energyDocumentAssets ?? []), ...(move?.energyMeterReadingAssets ?? [])];
        return this.uploader.queue
            .map((item) => item._file)
            .filter((file) => !uploadedAssets.some((asset) => asset.key === (file as unknown as Asset).key));
    }

    private getFilesToRemove(move: Move): File[] {
        const uploadedAssets = [...(move?.energyDocumentAssets ?? []), ...(move?.energyMeterReadingAssets ?? [])];
        return this.removedFiles?.filter((file) => uploadedAssets?.some((asset) => (file as unknown as Asset).key === asset?.key));
    }

    private getUploadCalls(filesToAdd: File[], move: Move): Observable<any>[] {
        if (ArrayUtils.isEmpty(filesToAdd)) return [];

        const formData = HttpUtils.addFiles(filesToAdd);
        return [this.moveSandbox.uploadEnergyMeterReadingAsset(DbUtils.getStringId(move), formData)];
    }

    private getDeleteCalls(filesToRemove: File[], move: Move): Observable<any>[] {
        if (ArrayUtils.isEmpty(filesToRemove)) return [];
        return filesToRemove.map((file) => this.moveSandbox.deleteEnergyAsset(DbUtils.getStringId(move), (file as unknown as Asset).key));
    }

    private onUploadFail = (): void => this.uiSandbox.hideLoadingOverlay();

    private setElectricityToggle = (gasValue: boolean) =>
        this.meterReadingsElectricity?.electricityForm
            .get(ElectricityFormFields.Active)
            [gasValue ? 'enable' : 'disable']({ emitEvent: false });

    private setGasToggle = (electricityValue: boolean) =>
        this.meterReadingsGas?.gasForm.get(gasControlNames.active)[electricityValue ? 'enable' : 'disable']({ emitEvent: false });
    protected readonly fields = ElectricityFormFields;
}
