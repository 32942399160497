import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MeterInfoBaseComponent } from '@app/energy/components/meter-info-base/meter-info-base.component';
import { MeterInfoElectricityComponent } from '@app/energy/components/meter-info-electricity/meter-info-electricity.component';
import { MeterInfoGasComponent } from '@app/energy/components/meter-info-gas/meter-info-gas.component';
import { MeterInfoAlertType } from '@app/energy/containers/meter-info/meter-info.constants';
import { adminMeterInfoForm } from '@app/energy/modals/admin-meter-info/admin-meter-info.constants';
import { EnergyMeterReadingAssetsInputComponent } from '@app/form/components/energy-meter-reading-assets-input/energy-meter-reading-assets-input.component';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { Movers } from '@app/move/interfaces/movers';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { FileModalData } from '@app/real-estate-agent/interfaces/file-modal-data';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { EnergyMeterReadingAssetDetailComponent } from '@app/real-estate-agent/modals/energy-meter-reading-asset-detail/energy-meter-reading-asset-detail.component';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { Asset, DbUtils, HttpUtils, I18nKeyType, MoveTransactionType, ObjectUtils, Role } from '@smooved/core';
import { ModalSandbox, NotificationSandbox, UiContext } from '@smooved/ui';
import { combineLatest, concat } from 'rxjs';
import { filter, last, startWith, takeUntil } from 'rxjs/operators';
import { optionElectricity, optionGas } from './meter-info.constants';
import { ElectricityMeterStore } from '@smooved-lib/lib/meters/store/electricity-meter.store';
import { GasMeterStore } from '@smooved-lib/lib/meters/store/gas-meter.store';
import { EnergyStopStore } from '@app/services/energy-stop/store/energy-stop.store';

@Component({
    selector: 'app-meter-info',
    templateUrl: './meter-info.component.html',
    styleUrls: ['./meter-info.component.scss'],
})
export class MeterInfoComponent extends MeterInfoBaseComponent implements OnInit {
    @ViewChild(MeterInfoElectricityComponent) public meterInfoElectricityComponent: MeterInfoElectricityComponent;
    @ViewChild(MeterInfoGasComponent) public meterInfoGasComponent: MeterInfoGasComponent;
    @ViewChild(EnergyMeterReadingAssetsInputComponent)
    public energyMeterReadingAssetsInputComponent: EnergyMeterReadingAssetsInputComponent;

    @Input() public data: Partial<ModalDataMove> = {};
    @Input() public canSetEnergyType: boolean;
    @Output() public success = new EventEmitter<Move>();

    public readonly moveTransactionTypes = MoveTransactionType;
    public readonly roles = Role;
    public readonly moverRole = MoverRole;
    public readonly meterInfoForm = adminMeterInfoForm;
    public readonly i18nKeys = { ...I18nKeyType, ...AppI18nKeyType };
    public readonly optionElectricity = optionElectricity;
    public readonly optionGas = optionGas;
    public readonly uiContext = UiContext;

    public form: UntypedFormGroup;
    public isDisabled = false;
    public moveTransactionType: MoveTransactionType;
    public meterInfoAlertTypes = MeterInfoAlertType;
    public movers: Movers;

    private filesChanged = false; // Flag to indicate if there were changes on files;

    public readonly electricityMeterStore = inject(ElectricityMeterStore);
    public readonly gasMeterStore = inject(GasMeterStore);
    public readonly energyStopStore = inject(EnergyStopStore);
    private readonly modalSandbox = inject(ModalSandbox);
    private readonly notificationSandbox = inject(NotificationSandbox);
    private readonly formBuilder = inject(UntypedFormBuilder);
    protected readonly uiSandbox = inject(AppUiSandbox);
    protected readonly moveSandbox;
    protected readonly authenticationSandbox;

    constructor() {
        const authenticationSandbox = inject(AuthenticationSandbox);
        const moveSandbox = inject(MoveSandbox);
        super(moveSandbox, authenticationSandbox);

        this.authenticationSandbox = authenticationSandbox;
        this.moveSandbox = moveSandbox;
    }

    public isDirty(): boolean {
        return this.form.dirty || this.meterInfoElectricityComponent?.form?.dirty || this.meterInfoGasComponent?.form?.dirty;
    }

    public ngOnInit(): void {
        this.form = this.formFactory();
        super.ngOnInit();

        this.movers = MoveUtils.getMovers(this.data.move);
        this.moveTransactionType = this.data.asTransactionType || this.data.move.moveStates?.transactionType;

        combineLatest<[Move, string | null]>([this.success, this.moveSandbox.idOnce$])
            .pipe(
                filter(([, id]) => !!id),
                takeUntil(this.destroy$)
            )
            .subscribe(([, id]) => {
                this.moveSandbox.fetch(id);
            });

        this.authenticationSandbox.isAdminOnce$.subscribe((isAdmin) => {
            this.isDisabled = isAdmin ? this.data.readOnly : !!this.data.readOnly || !!this.data.move.moveStates?.metersProcessedByAdmin;
            this.isDisabled ? this.form.disable() : this.form.enable();

            combineLatest([
                this.form
                    .get(adminMeterInfoForm.HasElectricity)
                    .valueChanges.pipe(startWith(this.form.get(adminMeterInfoForm.HasElectricity).value)),
                this.form.get(adminMeterInfoForm.HasGas).valueChanges.pipe(startWith(this.form.get(adminMeterInfoForm.HasGas).value)),
            ])
                .pipe(takeUntil(this.destroy$))
                .subscribe(this.handleEnergyType);
        });
    }

    public onSubmit(): void {
        if (this.form.invalid) return;
        this.uiSandbox.showLoadingOverlay();
        this.patchMove();
    }

    public onEnergyMeterReadingDetail(file: FileInput): void {
        this.openModal(EnergyMeterReadingAssetDetailComponent, this.modalDataFactory(file as Asset));
    }

    private formFactory(): FormGroup {
        const initialEnergyType = this.getInitialEnergyType();
        return this.formBuilder.group({
            [adminMeterInfoForm.MovingDate]: this.data.move.movingDate,
            [adminMeterInfoForm.EnergyType]: initialEnergyType,
            [adminMeterInfoForm.HasElectricity]: MoveUtils.isElectricityEnergyType(initialEnergyType),
            [adminMeterInfoForm.HasGas]: MoveUtils.isGasEnergyType(initialEnergyType),
        });
    }

    public hasAssets(): boolean {
        return (
            !!this.energyMeterReadingAssetsInputComponent?.files.length ||
            !!this.data.move.energyMeterReadingAssets?.length ||
            !!this.data.move.energyDocumentAssets?.length
        );
    }

    protected dataTakeOverInformationFactory(): Partial<Move> {
        return this.getPatchData();
    }

    protected getPatchData(): Partial<Move> {
        const movingDate = this.form.get(adminMeterInfoForm.MovingDate).value as Date;
        const patch = {
            ...super.getPatchData(),
            movingDate,
        };

        ObjectUtils.set(patch, 'energyOffer.energyType', this.form.get(adminMeterInfoForm.EnergyType).value);
        ObjectUtils.removeEmpty(patch, true);
        return patch;
    }

    protected onMetersUpdateSuccess(updatedMove: Move): void {
        if (updatedMove) this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
        this.success.emit(updatedMove || (this.filesChanged ? this.data?.move : null));
    }

    protected onUploadSuccess = (patchedMoveAfterAssets: Move): void => {
        this.uiSandbox.hideLoadingOverlay();
        this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
        this.energyMeterReadingAssetsInputComponent.files = [];
        this.energyMeterReadingAssetsInputComponent.previews = null;
        this.success.emit(patchedMoveAfterAssets);
    };

    protected setMove = (move: Move): void => {
        if (!move) return;
        this.filesChanged = true;
        this.data.move = move;
    };

    private async patchMove(): Promise<void> {
        let electricityMeterPatch = this.meterInfoElectricityComponent?.createPatch();
        let gasMeterPatch = this.meterInfoGasComponent?.createPatch();

        await this.electricityMeterStore.patch(DbUtils.getStringId(this.data.move), electricityMeterPatch);
        await this.gasMeterStore.patch(DbUtils.getStringId(this.data.move), gasMeterPatch);

        this.moveSandbox.patchProperty(
            '',
            this.getPatchData(),
            true,
            (move: Move) => this.uploadEnergyAssets(move),
            false,
            this.data?.move,
            false
        );

        this.energyStopStore.load(DbUtils.getStringId(this.data.move));
    }

    private onUploadFail = (): void => this.uiSandbox.hideLoadingOverlay();

    private openModal(componentForTablePortraitUp: ComponentType<any>, data: FileModalData): void {
        this.modalSandbox.openModal(null, null, null, componentForTablePortraitUp, { data }, this.setMove);
    }

    private modalDataFactory(file: Asset): FileModalData {
        return {
            moveId: DbUtils.getStringId(this.data.move),
            readOnly: this.isDisabled,
            file,
        };
    }

    private handleEnergyType = ([hasElectricity, hasGas]: boolean[]): void => {
        let energyType: EnergyType = EnergyType.Both;

        if (hasElectricity && !hasGas) energyType = EnergyType.Electricity;
        if (!hasElectricity && hasGas) energyType = EnergyType.Gas;

        this.enableFormControl(this.form.get(adminMeterInfoForm.HasElectricity), (!hasElectricity && hasGas) || (hasElectricity && hasGas));
        this.enableFormControl(this.form.get(adminMeterInfoForm.HasGas), (hasElectricity && !hasGas) || (hasElectricity && hasGas));

        this.form.get(adminMeterInfoForm.EnergyType).setValue(energyType);
    };

    private enableFormControl(control: AbstractControl, enable = true): void {
        enable && !this.isDisabled ? control.enable({ emitEvent: false }) : control.disable({ emitEvent: false });
    }

    private uploadEnergyAssets(move: Move): void {
        const httpCalls = [];
        if (this.energyMeterReadingAssetsInputComponent?.files?.length) {
            const formData = HttpUtils.addFiles(this.energyMeterReadingAssetsInputComponent.files);
            httpCalls.push(this.moveSandbox.uploadEnergyMeterReadingAsset(DbUtils.getStringId(this.data.move._id), formData));
        }

        if (httpCalls.length) {
            this.uiSandbox.showLoadingOverlay();

            concat(...httpCalls)
                .pipe(last())
                .subscribe(this.onUploadSuccess, this.onUploadFail);
        } else {
            this.onMetersUpdateSuccess(move);
        }
    }
}
