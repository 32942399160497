import { CheckInput, UiHeaderVariant } from '@smooved/ui';

export const optionGas: CheckInput<boolean> = {
    id: 'has-gas',
    labelResource: 'GAS',
    value: true,
    name: 'has-gas',
    variant: UiHeaderVariant.H6,
};

export const optionElectricity: CheckInput<boolean> = {
    id: 'has-electricity',
    labelResource: 'ELECTRICITY',
    value: true,
    name: 'has-electricity',
    variant: UiHeaderVariant.H6,
};

export const validatedOptionElectricity: CheckInput<boolean> = {
    id: 'validated-electricity',
    labelResource: 'ENERGY.METER_READING.VALIDATED',
    value: true,
    name: 'validated-electricity',
};

export const validatedOptionGas: CheckInput<boolean> = {
    id: 'validated-gas',
    labelResource: 'ENERGY.METER_READING.VALIDATED',
    value: true,
    name: 'validated-gas',
};

export const automaticReadingOptionGas: CheckInput<boolean> = {
    id: 'automatic-reading-gas',
    labelResource: 'ENERGY.METER_READING.DIGITAL.AUTOMATIC_READING',
    value: true,
    name: 'automatic-reading-gas',
};
