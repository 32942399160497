import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { DbUtils, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import { gasControlNames } from '@smooved/ui';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, map, take, takeUntil } from 'rxjs/operators';
import { Move } from '../../../move/interfaces/move';
import { GasMeter } from '@smooved-lib/lib/meters/types/gas-meter';
import { automaticReadingOptionGas, validatedOptionGas } from '@app/energy/components/meter-info/meter-info.constants';
import { GasMeterStore } from '@smooved-lib/lib/meters/store/gas-meter.store';
import { MoverRole } from '@app/move/enums/mover-role.enum';

@Component({
    selector: 'app-meter-info-gas',
    template: `
        <form [formGroup]="form" *ngIf="showGas">
            <div class="u-flex-column u-flex-align-items-start">
                <h6 class="u-color-muted u-margin-bottom" *ngIf="showTitle">{{ 'GAS' | translate }}</h6>

                <app-ean-code-input
                    class="u-margin-bottom-half"
                    [formControlName]="formControlNames.eanCodeGas"
                    [hasMargin]="false"
                    ngDefaultControl
                ></app-ean-code-input>

                <smvd-ui-text-input
                    [label]="'ENERGY.METER_NUMBER.LABEL' | translate"
                    [formControlName]="formControlNames.meterNumberGasByAdmin"
                    ngDefaultControl
                ></smvd-ui-text-input>

                <app-check-input
                    [option]="validatedOptionGas"
                    class="u-margin-bottom"
                    [hasMargin]="true"
                    [hasMarginDouble]="false"
                    [custom]="false"
                    width="auto"
                    [formControlName]="formControlNames.validatedGas"
                >
                </app-check-input>

                <app-meter-reading-input
                    [formControlName]="formControlNames.gasMeterReading"
                    ngDefaultControl
                    *ngIf="!isEots"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    [label]="'METER_READING' | translate"
                ></app-meter-reading-input>

                @if (meterTypeIsDigital && isAdmin) {
                    <app-check-input
                        [option]="automaticReadingOptionGas"
                        [hasMargin]="false"
                        [hasMarginDouble]="false"
                        [custom]="false"
                        width="auto"
                        [formControlName]="formControlNames.automaticReadingGas"
                    >
                    </app-check-input>
                }
            </div>
        </form>
    `,
})
export class MeterInfoGasComponent extends RxjsComponent implements OnInit, OnChanges {
    @Input() public isEots: boolean;
    @Input() public move: Move;
    @Input() public showTitle = true;
    @Input() public disabled = false;
    @Input() public readingDisabled = false;
    @Input() public show: boolean;

    @Output() public formChange: EventEmitter<void> = new EventEmitter<void>();

    public showGas: boolean;
    public form: UntypedFormGroup;
    public isAdmin = false;
    public readonly formControlNames = gasControlNames;

    public meterTypeIsDigital = false;

    public readonly gasMeterStore = inject(GasMeterStore);
    public readonly moveSandbox = inject(MoveSandbox);
    public readonly authenticationSandbox = inject(AuthenticationSandbox);
    public readonly formBuilder = inject(UntypedFormBuilder);
    public readonly cdr = inject(ChangeDetectorRef);
    public readonly route = inject(ActivatedRoute);

    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.authenticationSandbox.isAdminOnce$.subscribe(async (isAdmin) => {
            this.isAdmin = isAdmin;

            this.form = this.formBuilder.group({
                [gasControlNames.eanCodeGas]: [{ value: null, disabled: this.disabled }],
                [gasControlNames.meterNumberGasByAdmin]: [{ value: null, disabled: this.disabled }],
                [gasControlNames.validatedGas]: [{ value: false, disabled: this.disabled }],
                [gasControlNames.automaticReadingGas]: [{ value: false, disabled: this.disabled }],
                [gasControlNames.gasMeterReading]: [{ value: null, disabled: !this.canEditMeterReading() }],
            });

            if (this.move) {
                const { transferee } = MoveUtils.getMovers(this.move);
                await this.gasMeterStore.load(DbUtils.getStringId(transferee));

                const energyType = this.move?.energyOffer?.energyType;
                const showGas =
                    this.show || !energyType || MoveUtils.showMeterReadingGas(this.move) || !!MoveUtils.energyTransferSelected(this.move);

                this.init(this.move, this.gasMeterStore.meter(), showGas);
            } else {
                combineLatest([
                    this.moveSandbox.moveOnce$,
                    this.moveSandbox.energyTransferSelectedOnce$,
                    this.moveSandbox.energyTypeHasGas$.pipe(take(1)),
                    of(this.route.snapshot.queryParams.id).pipe(take(1)),
                ])
                    .pipe(
                        concatMap(([moveState, energyTransferSelected, energyTypeHasGas, id]): Observable<any> => {
                            const moveId = id || moveState?._id;
                            if (!moveId) {
                                return of([moveState || null, energyTypeHasGas || !!energyTransferSelected]);
                            } else {
                                return this.moveSandbox.get(moveId).pipe(
                                    map((move) => {
                                        const energyType = move.energyOffer?.energyType;
                                        const showGas = !energyType || EnergyUtils.hasGas(energyType) || !!energyTransferSelected;
                                        return [move, showGas];
                                    }),
                                    catchError((e) => of([moveState || null, energyTypeHasGas || !!energyTransferSelected]))
                                );
                            }
                        })
                    )
                    .subscribe(async ([move, showGas]) => {
                        const { transferee } = MoveUtils.getMovers(move);
                        await this.gasMeterStore.load(DbUtils.getStringId(transferee));

                        this.init(move, this.gasMeterStore.meter(), showGas);
                    });
            }
        });
    }

    public ngOnChanges({ disabled, readingDisabled }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(disabled) || SimpleChangesUtils.hasChanged(readingDisabled)) {
            this.setFormControlDisabled(gasControlNames.eanCodeGas, this.disabled);
            this.setFormControlDisabled(gasControlNames.gasMeterReading, !this.canEditMeterReading());
            this.setFormControlDisabled(gasControlNames.meterNumberGasByAdmin, this.disabled);
            this.setFormControlDisabled(gasControlNames.validatedGas, this.disabled);
        }
    }

    public createPatch(): GasMeter {
        return {
            eanCode: this.showGas && !!this.eanCodeGasFormControl().value ? (this.eanCodeGasFormControl().value as string) : null,
            meterReading:
                this.showGas && !!this.gasMeterReadingFormControl().value ? (this.gasMeterReadingFormControl().value as string) : null,
            meterNumber: this.showGas && (this.form.get(gasControlNames.meterNumberGasByAdmin).value as string),
            validated: !!this.form.get(gasControlNames.validatedGas).value,
            automaticReading: this.meterTypeIsDigital ? !!this.form.get(gasControlNames.automaticReadingGas).value : null,
        };
    }

    public meterTypeIsDigitalListener(isDigital: boolean): void {
        this.meterTypeIsDigital = isDigital;
    }

    private init(move: Move, gasMeter: GasMeter, showGas: boolean): void {
        this.showGas = showGas;
        if (!move || !showGas) {
            return;
        }
        this.eanCodeGasFormControl().patchValue(gasMeter.eanCode);
        this.gasMeterReadingFormControl().patchValue(gasMeter.meterReading);
        this.form.get(gasControlNames.automaticReadingGas).patchValue(gasMeter.automaticReading);
        this.form.get(gasControlNames.meterNumberGasByAdmin).patchValue(gasMeter.meterNumber);
        this.form.get(gasControlNames.validatedGas).patchValue(gasMeter.validated);

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((x) => {
            this.formChange.emit();
        });
        this.cdr.detectChanges();
    }

    private eanCodeGasFormControl(): AbstractControl {
        return this.form.get(gasControlNames.eanCodeGas);
    }

    private gasMeterReadingFormControl(): AbstractControl {
        return this.form.get(gasControlNames.gasMeterReading);
    }

    private setFormControlDisabled(formControlName: string, disabled: boolean): void {
        this.form?.get(formControlName)[disabled ? 'disable' : 'enable']();
    }

    private canEditMeterReading(): boolean {
        return !(this.disabled || this.readingDisabled);
    }

    protected readonly validatedOptionGas = validatedOptionGas;
    protected readonly automaticReadingOptionGas = automaticReadingOptionGas;
    protected readonly MoverRole = MoverRole;
}
