import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AdminMeterInfoModal } from '@app/energy/modals/admin-meter-info/admin-meter-info.modal';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { AuditUtils, DbUtils, inject, MoveTransactionType } from '@smooved/core';
import { ModalSandbox, Svg } from '@smooved/ui';
import { EnergyStopStore } from '@app/services/energy-stop/store/energy-stop.store';
import { MoveUtils } from '@app/move/state/move.utils';

@Component({
    selector: 'app-service-meter-readings',
    template: `
        <div *ngIf="!!move" [ngSwitch]="move.moveStates?.meterComplete">
            <div *ngSwitchCase="false" class="u-flex-column u-flex-align-items-start">
                <div class="u-flex-row u-flex-align-items-start u-color-error">
                    <app-svg width="24" height="24" class="u-margin-right-half" [name]="alertSvg"></app-svg>
                    <div class="u-flex-column u-flex-align-items-start">
                        <span class="u-margin-bottom-half">{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.METER_READINGS.NOK' | translate }}</span>
                        <app-button (onClick)="openMeterInfo()"
                            >{{
                                (!!move.energyDocumentsMovingAddressByAdmin ? 'MOVE.METER_READING.READ' : 'MOVE.METER_READING.EDIT')
                                    | translate
                            }}
                        </app-button>
                    </div>
                </div>
            </div>

            <div *ngSwitchCase="true">
                <div class="u-flex-row u-flex-align-items-start">
                    <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                    <div class="u-flex-column u-flex-align-items-start">
                        <span>{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.METER_READINGS.OK' | translate }}</span>
                        <a class="u-link" (click)="openMeterInfo()">{{
                            (!!move.energyDocumentsMovingAddressByAdmin ? 'MOVE.METER_READING.READ' : 'MOVE.METER_READING.EDIT') | translate
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [],
})
export class ServiceEnergyMeterReadingsComponent extends BaseServiceActionComponent implements OnInit {
    @Input() public id: string;

    public alertSvg = Svg.Alert;

    public readonly energyStopStore = inject(EnergyStopStore);

    constructor(
        protected readonly cdr: ChangeDetectorRef,
        private readonly modalSandbox: ModalSandbox,
        public readonly moveSandbox: MoveSandbox
    ) {
        super(cdr, moveSandbox);
    }

    public ngOnInit(): void {
        if (!this.move) {
            this.getMove();
        }
    }

    public openMeterInfo(): void {
        const data: ModalDataMove = {
            move: this.move,
            asTransactionType: this.move.user.role === MoverRole.Leaver ? MoveTransactionType.Rental : this.move.moveStates.transactionType,
        };

        this.modalSandbox.openModal(AdminMeterInfoModal, { data }, null, AdminMeterInfoModal, { data }, (move) => {
            if (move) {
                this.updateMove(move);
            }
        });
    }

    private getMove(): void {
        this.moveSandbox.get(this.id).subscribe((move) => {
            this.moveSandbox.setMoveState(move);
            this.updateMove(move);
            const { transferee } = MoveUtils.getMovers(move);
            this.energyStopStore.load(DbUtils.getStringId(transferee));
        });
    }
}
